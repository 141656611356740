
import { Vue, Component } from 'vue-property-decorator'
import AuthService from '@/services/auth_service'
import { Roles } from '@/models/user/roles'
import { GenericError } from '@/services/error_service'

@Component
export default class Success extends Vue {

  created() {
    if (!this.$route.query.success) {
      this.$router.push('/')
    }
  }
}
