import { render, staticRenderFns } from "./Success.vue?vue&type=template&id=0f24d590&scoped=true&"
import script from "./Success.vue?vue&type=script&lang=ts&"
export * from "./Success.vue?vue&type=script&lang=ts&"
import style0 from "./Success.vue?vue&type=style&index=0&id=0f24d590&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f24d590",
  null
  
)

export default component.exports